<template>
    <div
        style="width: 100%; display: flex; justify-content: center;"
        >
        <panel class="icph-signup-form">
            <h1 class="icph-lineunder">Nominate to receive ICPH Briefings</h1>
            <p class="icph-signup-form-tagline">
                Welcome to the International Climate Politics Hub website.<br><br>
                If you think you may find the ICPH Briefings & Strategies useful for your work, please do fill in this secured form to submit an addition request. The ICPH Secretariat will consider it as soon as possible.<br><br>
                Filling this form will help us provide you with the most strategic service possible.
            </p>
            <form @submit.prevent="signup">
                <base-input
                    required
                    label="First Name"
                    type="text"
                    placeholder="First Name..."
                    autocomplete="given-name"
                    v-model="model.first_name"
                    id="first_name"
                    :error="validationErrors.first_name ? validationErrors.first_name[0] : ''"
                >
                </base-input>
                <base-input
                    required
                    label="Surname"
                    type="text"
                    placeholder="Surname..."
                    v-model="model.last_name"
                    autocomplete="family-name"
                    id="last_name"
                    :error="validationErrors.last_name ? validationErrors.last_name[0] : ''"

                    >
                </base-input>
                <base-input
                    required
                    label="E-mail Address"
                    type="email"
                    placeholder="E-mail Address..."
                    v-model="model.email"
                    autocomplete="email"
                    id="email"
                    :error="validationErrors.email ? validationErrors.email[0] : ''"
                >
                </base-input>
                <base-input
                    required
                    label="Organisation"
                    type="text"
                    placeholder="Organisation..."
                    v-model="model.organisation"
                    id="organisation"
                    :error="validationErrors.organisation ? validationErrors.organisation[0] : ''"
                >
                </base-input>
                <base-input
                    required
                    label="Position"
                    type="text"
                    placeholder="Position..."
                    v-model="model.position"
                    id="position"
                    :error="validationErrors.position ? validationErrors.position[0] : ''"
                >
                </base-input>
                <multi-select
                    required
                    label="What is your main thematic focus of work?"
                    v-model="model.thematic_focus"
                    :options="topics"
                    placeholder="Select topics"
                    :error="validationErrors.thematic_focus ? validationErrors.thematic_focus[0] : ''"
                >
                </multi-select>
                <drop-down
                    required
                    label="In which country are you based?"
                    v-model="model.country"
                    :options="countries"
                    :error="validationErrors.country ? validationErrors.country[0] : ''"
                >
                </drop-down>
                <multi-select
                    required
                    label="What is your main geographic region work focus?"
                    v-model="model.geographic_region_focus"
                    :options="regions"
                    placeholder="Select regions"
                    :error="validationErrors.geographic_region_focus ? validationErrors.geographic_region_focus[0] : ''"
                >
                </multi-select>
                <multi-select
                    label="What is your main geographic country work focus?"
                    v-model="model.geographic_country_focus"
                    :options="countries"
                    placeholder="Select countries"
                    :error="validationErrors.geographic_country_focus ? validationErrors.geographic_country_focus[0] : ''"
                >
                </multi-select>
                <multi-select
                    required
                    label="What is your level of political access?"
                    v-model="model.political_access"
                    :options="level_of_access_options"
                    placeholder="Select levels of access"
                    :error="validationErrors.political_access ? validationErrors.political_access[0] : ''"
                >
                </multi-select>
                <base-input
                    label="Institution/organization access"
                    type="text"
                    placeholder=""
                    v-model="model.institution_access"
                    id="institution_access"
                    :error="validationErrors.institution_access ? validationErrors.institution_access[0] : ''"
                >
                </base-input>
                <base-input
                    label="Additional comments"
                    type="text"
                    placeholder=""
                    v-model="model.additional_comment"
                    id="additional_comment"
                    :error="validationErrors.additional_comment ? validationErrors.additional_comment[0] : ''"
                >
                </base-input>

                <PrivacyPolicy></PrivacyPolicy>
                <check-box
                    v-model="model.privacy_consent"
                    :error="validationErrors.privacy_consent ? validationErrors.privacy_consent[0] : ''"
                >
                </check-box>

                <h1>ATTENTION!</h1>

                <p>Please add <a href="mailto:info@ICPHub.org">&lt;info@ICPHub.org&gt;</a> to your contact list, otherwise, you risk having our briefing emails go to junk/spam.</p>
                <check-box
                    v-model="model.attention"
                    :error="validationErrors.attention ? validationErrors.attention[0] : ''"
                >
                </check-box>

                <Button type="submit" :disabled="loading">Submit</Button>
                <p class="icph-signup-form-help">If you're having any issues please contact <a href="mailto:info@icphub.org">info@icphub.org</a></p>

                <ul class="icph-signup-form-errors">
                    <li v-for="(error, index) in validationErrors" :key="index">{{ error[0] }}</li>
                </ul>
            </form>
        </panel>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    import Panel from '@/components/Panels/Panel';
    import BaseInput from '@/components/Inputs/BaseInput';
    import DropDown from '@/components/Inputs/Dropdown';
    import CheckBox from '@/components/Inputs/Checkbox';
    import PrivacyPolicy from '@/pages/Auth/PrivacyPolicy';
    import {
        AirtableCountryService,
        AirtableRegionService,
        AirtableThematicFocusService,
        AirtableLevelOfAccessService
    } from '@/services';
    import MultiSelect from '@/components/Inputs/MultiSelect';
    import Button from '@/components/Buttons/Button';

    export default
    {
        name: 'SignUpForm',
        components:
        {
            Panel,
            BaseInput,
            Button,
            DropDown,
            CheckBox,
            MultiSelect,
            PrivacyPolicy,
        },
        data()
        {
            return {
                topics: [],
                countries: [],
                regions: [],
                country: {},
                submitted: false,
                loading: false,
                model:
                {
                    first_name: '',
                    last_name: '',
                    email: '',
                    organisation: '',
                    position: '',
                    thematic_focus: '',
                    country: '',
                    geographic_region_focus: '',
                    geographic_country_focus: '',
                    political_access: '',
                    institution_access: '',
                    additional_comment: '',
                    privacy_consent: false,
                    attention: false,

                },
                level_of_access_options: [],
            };
        },
        computed:
        {
            ...mapGetters('Auth',
            [
                'authenticating',
                'error',
                'validationErrors'
            ]),
        },
        methods:
        {
            async signup()
            {
                this.loading = true;

                try {
                    await this.$store.dispatch('Auth/signup',
                    {
                        first_name: this.model.first_name,
                        last_name: this.model.last_name,
                        email: this.model.email,
                        organisation: this.model.organisation,
                        position: this.model.position,
                        thematic_focus: this.model.thematic_focus,
                        country: this.model.country,
                        geographic_region_focus: this.model.geographic_region_focus,
                        geographic_country_focus: this.model.geographic_country_focus,
                        political_access: this.model.political_access,
                        institution_access: this.model.institution_access,
                        additional_comment: this.model.additional_comment,
                        privacy_consent: this.model.privacy_consent,
                        attention: this.model.attention,
                    });

                } catch (ex) {
                    console.log(ex)
                }

                this.loading = false;
                // this.$router.push('/');
            },
        },
        async mounted()
        {
            this.countries = (await AirtableCountryService.all()).data;
            this.topics = (await AirtableThematicFocusService.all()).data;
            this.regions = (await AirtableRegionService.all()).data;
            this.level_of_access_options = (await AirtableLevelOfAccessService.all()).data

            this.countries.forEach((item) =>
            {
                item.value = item.name;
                item.label = item.name;
            });
        },
    }
</script>
