<template>
    <popup class="icph-popup-profile" title="User Profile" subtitle="Please fill in your information." color="primary" v-model:show="show">
        <perfect-scrollbar
            tag="form"
            @submit.prevent="update_profile"
            :options="{ suppressScrollX: true }"
        >
            <p>Please update your profile and ensure all necessary information is complete. Note that unless you are a member of the Global Team, your profile information will remain confidential and only viewable to the ICPH Secretariat.</p>
            <base-input
                required
                round
                dark
                label="First Name"
                type="text"
                placeholder="First Name..."
                autocomplete="given-name"
                v-model="model.first_name"
                id="first_name"
                :error="validationErrors.first_name ? validationErrors.first_name[0] : ''"
                >
            </base-input>
            <base-input
                required
                round
                dark
                label="Surname"
                type="text"
                placeholder="Surname..."
                v-model="model.last_name"
                autocomplete="family-name"
                id="last_name"
                :error="validationErrors.last_name ? validationErrors.last_name[0] : ''"

                >
            </base-input>
            <base-input
                required
                round
                dark
                label="E-mail Address"
                type="email"
                placeholder="E-mail Address..."
                v-model="model.email"
                autocomplete="email"
                id="email"
                disabled
                :error="validationErrors.email ? validationErrors.email[0] : ''"
            >
            </base-input>
            <base-input
                required
                round
                dark
                label="Organisation"
                type="text"
                placeholder="Organisation..."
                v-model="model.organization"
                id="organisation"
                :error="validationErrors.organization ? validationErrors.organization[0] : ''"
            >
            </base-input>
            <base-input
                required
                round
                dark
                label="Position"
                type="text"
                placeholder="Position..."
                v-model="model.position"
                id="position"
                :error="validationErrors.position ? validationErrors.position[0] : ''"
            >
            </base-input>
            <multi-select
                required
                round
                dark
                label="What is your main thematic focus of work?"
                v-model="model.thematic_focus"
                :options="topics"
                placeholder="Select topics"
                :error="validationErrors.thematic_focus ? validationErrors.thematic_focus[0] : ''"
            >
            </multi-select>
            <drop-down
                required
                round
                dark
                label="In which country are you based?"
                v-model="model.country"
                :placeholder="truncatedCountry"
                :options="countries"
                :error="validationErrors.countries ? validationErrors.countries[0] : ''"
            >
            </drop-down>
            <multi-select
                required
                round
                dark
                label="What is your main geographic region work focus?"
                v-model="model.geographic_region_focus"
                :options="regions"
                placeholder="Select regions"
                :error="validationErrors.geographic_region_focus ? validationErrors.geographic_region_focus[0] : ''"
            >
            </multi-select>
            <multi-select
                round
                dark
                label="What is your main geographic country work focus?"
                v-model="model.geographic_country_focus"
                :options="countries"
                placeholder="Select countries"
                :error="validationErrors.geographic_country_focus ? validationErrors.geographic_country_focus[0] : ''"
            >
            </multi-select>
            <multi-select
                required
                round
                dark
                label="What is your level of political access?"
                v-model="model.political_access"
                :options="level_of_access_options"
                placeholder="Select levels of access"
                :error="validationErrors.political_access ? validationErrors.political_access[0] : ''"
            >
            </multi-select>
            <base-input
                round
                dark
                label="Institution/organization access ?"
                type="text"
                placeholder=""
                v-model="model.institution_access"
                id="institution_access"
                :error="validationErrors.institution_access ? validationErrors.institution_access[0] : ''"
            >
            </base-input>
            <upload-input
                :round="true"
                :dark="true"
                v-model="model.profile_picture"
                @file="profile_picture_changed"
                icon-right="icon-upload"
                accept="images"
                label="UPLOAD PROFILE PICTURE"
                placeholder="Choose a profile picture from your computer (2Mb. Max)"
                >
            </upload-input>
            <profile-picture
                :dark="true"
                :src="(profile_picture ? profile_picture : get_user_picture())"
                >
                <div class="icph-profile-picture-info">
                    <p>Your profile picture will appear in a circle on your profile, so it is important to maintain a 1:1 aspect ratio. The best file types are JPG or PNG.</p>
                    <button
                        class="icph-remove-picture"
                        v-if="profile_picture"
                        @click.prevent="remove_profile_picture"
                    >
                        Remove current profile picture x
                    </button>
                </div>
            </profile-picture>
            <base-input
                :round="true"
                :dark="true"
                :error="get_errors('password')"
                v-model="model.password"
                class="icph-password-input"
                type="password"
                label="NEW PASSWORD"
                placeholder="Please enter new password here"
                >
            </base-input>
            <base-input
                :round="true"
                :dark="true"
                :error="get_errors('password_confirmation')"
                v-model="model.password_confirmation"
                class="icph-password-input"
                type="password"
                label="CONFIRM NEW PASSWORD"
                placeholder="Please confirm new password here"
                >
            </base-input>
            <Button
                class="icph-save-button"
                color="secondary"
                :round="true"
                type="submit"
                >
                SAVE PROFILE
            </Button>
            <Button
                class="icph-logout"
                color="secondary"
                :round="true"
                @click="logout"
                >
                LOGOUT
            </Button>
        </perfect-scrollbar>
    </popup>
</template>

<script>
    import Popup from  '@/components/Popup';
    import BaseInput from '@/components/Inputs/BaseInput';
    import UploadInput from '@/components/Inputs/UploadInput';
    import DropDown from '@/components/Inputs/Dropdown';
    import MultiSelect from '@/components/Inputs/MultiSelect';
    import Button from '@/components/Buttons/Button';
    import ProfilePicture from '@/components/ProfilePicture';

    import {
        AirtableCountryService,
        AirtableRegionService,
        AirtableThematicFocusService,
        AirtableLevelOfAccessService,
        UserService
    } from '@/services';

    import useVuelidate from '@vuelidate/core';
    import { helpers, minLength } from '@vuelidate/validators';
    import { mapGetters } from 'vuex';

    export default
    {
        inheritAttrs: false,
        name: 'ProfilePopup',
        components:
        {
            Popup,
            ProfilePicture,
            BaseInput,
            UploadInput,
            DropDown,
            MultiSelect,
            Button,
        },
        props:
        {
            modelValue: Boolean,
        },
        emits: ['update:modelValue', 'close'],
        setup()
        {
            return { v$: useVuelidate() };
        },
        data()
        {
            return {
                countries: [],
                topics: [],
                country: {},
                regions: [],
                level_of_access_options: [],
                profile_picture: '',
                model:
                {
                    first_name: '',
                    last_name: '',
                    email: '',
                    organization: '',
                    position: '',
                    thematic_focus: '',
                    country: '',
                    geographic_region_focus: '',
                    geographic_country_focus: '',
                    political_access: '',
                    institution_access: '',
                    profile_picture: '',
                    password: '',
                    password_confirmation: '',
                },
            };
        },
        validations()
        {
            return {
                model:
                {
                    password: { minLength: minLength(6) },
                    password_confirmation: {
                        sameAsPass: helpers.withMessage('Confirm password should be the same as password', () => this.model.password === this.model.password_confirmation)
                    },
                },
            };
        },
        computed:
        {
            user()
            {
                return this.$store.getters['Auth/user'];
            },
            show:
            {
                get()
                {
                    return this.modelValue;
                },
                set(value)
                {
                    this.$emit('update:modelValue', value);
                },
            },
            ...mapGetters('Auth',
            [
                'authenticating',
                'error',
                'validationErrors'
            ]),
            truncatedCountry()
            {
                if(this.model.country) {
                    return this.model.country.length > 14
                        ? this.model.country.substring(0, 14) + '...'
                        : this.model.country;
                } else {
                    return 'Please select a country';
                }
            },
        },
        async mounted()
        {
            this.model = {...this.model, ...this.user};

            this.countries = (await AirtableCountryService.all()).data;
            this.topics = (await AirtableThematicFocusService.all()).data;
            this.regions = (await AirtableRegionService.all()).data;
            this.level_of_access_options = (await AirtableLevelOfAccessService.all()).data

            this.countries.forEach((item) =>
            {
                item.value = item.name;
                item.label = item.name;
            });
            this.model.profile_picture = (this.user.profile_picture_id ? process.env.VUE_APP_BACKEND_URL + 'api/files/' + this.user.profile_picture_id : 0);

            // this.model.country_id = String(this.model.country_id);


            this.model.countries.forEach((item, index) =>
            {
                this.model.countries[index] = String(item);
            });

            this.topics.forEach((item) =>
            {
                item.value = item.name;
                item.label = item.name;
            });
        },
        methods:
        {
            profile_picture_changed()
            {
                this.profile_picture = URL.createObjectURL(this.model.profile_picture);
            },
            async update_profile()
            {
                const is_valid = await this.v$.$validate();

                if (!is_valid)
                {
                    return ;
                }

                let form_data = new FormData();

                form_data.append('email', this.model.email !== null ? this.model.email : '');
                form_data.append('first_name', this.model.first_name !== null ? this.model.first_name : '');
                form_data.append('last_name', this.model.last_name !== null ? this.model.last_name : '');
                form_data.append('position', this.model.position !== null ? this.model.position : '');
                form_data.append('thematic_focus', this.model.thematic_focus !== null ? this.model.thematic_focus : '');
                form_data.append('organization', this.model.organization !== null ? this.model.organization : '');
                form_data.append('profile_picture', this.model.profile_picture !== null ? this.model.profile_picture : '');
                form_data.append('country', this.model.country !== null ? this.model.country : '');
                form_data.append('password', this.model.password);
                form_data.append('password_confirmation', this.model.password_confirmation);
                form_data.append('countries', this.model.countries !== null ? this.model.countries : '');
                form_data.append('geographic_region_focus', this.model.geographic_region_focus !== null ? this.model.geographic_region_focus : '');
                form_data.append('geographic_country_focus', this.model.geographic_country_focus !== null ? this.model.geographic_country_focus : '');
                form_data.append('political_access', this.model.political_access !== null ? this.model.political_access : '');
                form_data.append('institution_access', this.model.institution_access !== null ? this.model.institution_access : '');

                UserService.update(this.user.id, form_data).then(() =>
                {
                    this.$toast.success('User profile was successfully updated!');
                },
                (error) =>
                {
                    if (error.data.message)
                    {
                        this.$toast.error(error.data.message);
                    }
                    else
                    {
                        this.$toast.error('Something went wrong. Please try again!');
                    }
                });
            },
            remove_profile_picture()
            {
                this.model.profile_picture = {};
                this.profile_picture = undefined;
            },
            logout()
            {
                this.show = false;
                this.$router.push('/user/logout');
            },

            get_user_picture()
            {
                return (this.user.profile_picture_id ? process.env.VUE_APP_BACKEND_URL + 'api/files/' + this.user.profile_picture_id : 0)
            },

            get_errors(field, message)
            {
                const $err = this.v$.model[field];

                if ($err.$error)
                {
                    return message || $err.$errors.map((err) => err.$message).join(' - ');
                }

                return false;
            }
        }
    }
</script>
